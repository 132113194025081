import React, { useEffect, useState }  from 'react'
import Banner from '../components/Banner';
import Layout from '../layout/Layout'
import Seo from '../layout/Seo';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { AyudaBanner } from '../components/AyudaBanner';
import { Thank } from '../components/Quote/Thank';
import { Modal } from '../components/Modal';
import nprogress from "nprogress"
import { fetchApiQueryPOST } from '../api/quote';
import { BannerImages } from '../components/BannerImages';

const Contact = ({ pageContext }) => {

    const { locale } = pageContext;
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        tipo: '',
        nombre: '',
        apellido: '',
        email: '',
        mensaje: '',
        area: '',
        telefono: '',
    });
    const [thank, setThank] = useState(false);
    const handleButtonClick = ()=>{

        if(state.nombre === '' || state.apellido === '' || state.email === '' || state.mensaje === ''){

            setIsOpen(true);
            setThank(false);
        }
        else{
            nprogress.start();
            const params = {
                'email': state.email,
                'nombres': state.apellido +' '+ state.nombre,
                'telefono': state.area +' '+ state.telefono,
                'pagina':'contacto',
                'descripcion':state.mensaje + ' ' + state.tipo,
            }
            fetchApiQueryPOST("quote/asesor",params)
                    .then( (  result ) => {
                        console.log('quote/asesor', result);
                    });
            setIsOpen(false);
            setThank(true);
            nprogress.done();
        }

        console.log('contact done');
    }

    const handleInputChange = ( { target } ) => {
        setState({
            ...state,
            [target.name]: target.value
        });
    }

    useEffect(() => {
        
        return () => {
            
        }
    }, [state])


    function Child({locale, input}){
        return(
            <>
 <Seo 
                title="Formulario de Contacto" 
                description="Obra Ya contáctanos"
                pathname="https:localhost"
            />
            <Banner title={locale.contact.banner.title} subtitle={locale.contact.banner.subtitle} />
            {
                isOpen && <Modal title={'Información'} body={'Debe llenar todos los campos'} setIsOpen={setIsOpen} />
            }
            <section className="p-5 md:p-2 font-serif text-secondary">
                <div className="flex flex-col md:flex-row self-center lg:max-w-6xl rounded-lg bg-white m-auto mt-3 md:mt-6 text-center p-3">
                    <div className="flex flex-col p-1 md:p-5">
                        <span className="uppercase text-2xl font-light">
                            {locale.contact.title} <br /><br />
                        </span>
                        <span className="md:w-2/3 m-auto font-light text-xs md:text-base">
                            {locale.contact.section}
                            <br /><br />
                            {locale.contact.section2}
                            <br/><br/>
                            <div className="flex flex-col md:flex-row items-center my-4  place-content-center text-base">
                                <span className="mt-2">{locale.contact.form.msj} </span>
                                <div className="flex space-x-6 ml-3">
                                    <label className="inline-flex items-center mt-3">
                                        <input name="tipo" type="radio" className="form-radio h-5 w-5" value="Nuevo"  checked onChange={handleInputChange}/>
                                        <span className="ml-2  text-sm">{locale.contact.form.particular}</span>
                                    </label>
                                    <label className="inline-flex items-center mt-3">
                                        <input name="tipo" type="radio" className="form-radio h-5 w-5" value="Existente" onChange={handleInputChange}/>
                                        <span className="ml-2  text-sm">{locale.contact.form.provider}</span>
                                    </label>
                                </div>
                            </div>
                        </span>
                        <div className="flex flex-col md:flex-row p-1 md:p-5 md:px-12 place-content-center md:space-x-4 text-left">
                            <div className="flex flex-row md:w-1/2">
                                <div className="flex flex-col w-full">
                                    <div className="flex-inline items-center md:mt-3 w-full">
                                        <span className=" font-bolds text-xs">
                                            {locale.contact.form.name} 
                                        </span> <br />
                                        <input name="nombre" type="text" className="relative w-full bg-white border border-secondary rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:bg-terciary focus:border-primary sm:text-sm mt-2" onChange={handleInputChange} />
                                    </div>
                                    <div className="flex-inline items-center mt-3 w-full">
                                        <span className=" font-bolds text-xs">
                                            {locale.contact.form.surname}  
                                        </span> <br />
                                        <input name="apellido" type="text" className="relative w-full bg-white border border-secondary rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:bg-terciary focus:border-primary sm:text-sm mt-2" onChange={handleInputChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row md:w-1/2">
                                <div className="flex flex-col w-full">
                                    <div className="flex-inline items-center mt-3 w-full">
                                        <span className=" font-bolds text-xs">
                                            {locale.contact.form.mail}
                                        </span> <br />
                                        <input name="email" type="email" className="relative w-full bg-white border border-secondary rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:bg-terciary focus:border-primary sm:text-sm mt-2" onChange={handleInputChange}/>
                                    </div>
                                    <div className="flex flex-row space-x-6">
                                        <div className="flex md:flex-grow w-2/5 md:w-1/5">
                                            <div className="flex-inline items-center mt-3 w-full">
                                                <span className=" font-bolds text-xs">
                                                    {locale.contact.form.phone}
                                                </span>
                                                <PhoneInput 
                                                    country={input.subdomain}
                                                    value={state.phone}
                                                    onChange={handleInputChange}
                                                    name="area"
                                                    containerStyle={{marginTop:'0.5rem', cursor: 'not-allowed'}}
                                                    inputStyle={{borderColor: '#646464', width: '110px', height: '38px'}}
                                                    buttonStyle={{borderColor: '#646464', borderRight: 'none', backgroundColor: 'white'}}
                                                    countryCodeEditable={false}
                                                    />
                                            </div>
                                        </div>
                                        <div className="flex w-3/5 md:w-4/5">
                                            <div className="flex-inline items-center mt-3 w-full">
                                                <span className="font-bolds text-xs">
                                                    &nbsp;
                                                </span> <br />
                                                <input name="telefono" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" type="tel" className="relative w-full bg-white border border-secondary rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:bg-terciary focus:border-primary sm:text-sm mt-2" onChange={handleInputChange} style={{ height: '38px'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-1 md:p-5 md:px-12 place-content-right text-left">
                            <div className="flex-inline items-center mt-2 w-full">
                                <span className=" font-bolds text-xs">
                                    {locale.contact.form.msjLabel}    
                                </span> <br />
                                <textarea rows="6" name="mensaje" type="text" className="relative w-full bg-white border border-secondary rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:bg-terciary focus:border-primary sm:text-sm mt-2" onChange={handleInputChange}/>
                            </div>
                            <div className="grid md:items-right justify-items-stretch">
                                <button className="bg-primary hover:border-secondary cursor-pointer rounded-lg text-white justify-self-end p-3 w-full md:w-1/3" onClick={handleButtonClick}>
                                    <span className="text-center text-md uppercase">
                                        {locale.contact.form.msjLabel}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <AyudaBanner locale={locale} />
                <BannerImages locale={locale}></BannerImages>
            </section>
            {
            thank && <Thank />
            }
            </>
        )
    }
    
    return (
        <Layout locale={locale} background="bg-image-contact">
            {props => <Child {...props} />}
        </Layout>
    )
}
export default Contact
